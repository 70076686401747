
import moment from 'moment';

const Information = ({
  value,
}) => {
  return (
    <>
     
        <div className="container site-layout__content__addition-block">
          <h3 className="site-layout__content__addition-block__label">
            Additional Information <br />
            <p className="site-layout__content__addition-block__label__footer">
              __ _
            </p>
          </h3>
          <p>
            <span className="site-layout__content__addition-block__title">
              Release Date:
            </span>
            {moment(value?.created_at).format(
              'DD/MM/YYYY hh:mm ',
            )}
          </p>
          <p>
            <span className="site-layout__content__addition-block__title">
              Release Region:
            </span>{' '}
            {value?.release_region}
          </p>
          <p>
            <span className="site-layout__content__addition-block__title">
              Authorised by:
            </span>{' '}
            {value?.created_by?.first_name}{' '}
            {value?.created_by?.surname}
          </p>
        </div>
  
        <div className="container  site-layout__content__addition-block">
          <p>
            <span className="site-layout__content__addition-block__title">
              Updated Date:
            </span>{' '}
            {moment(value?.updated_at).format(
              'DD/MM/YYYY hh:mm ',
            )}
          </p>
          <p>
            <span className="site-layout__content__addition-block__title">
              Updated By:
            </span>{' '}
            {value?.updated_by?.first_name}{' '}
            {value?.updated_by?.surname}
          </p>
        </div>
    </>
  );
};

export default Information;
