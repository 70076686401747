import { Button, Col, Row, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDetailCompanyRules,
  sendEmailToGroup,
} from "../api/app-service/companyPolicyService";
import Information from "../components/information";
import Swal from "sweetalert2";
const { Text } = Typography;
const ViewPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [dataDetail, setDataDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const getDetailRule = async (id) => {
    const res = await getDetailCompanyRules(id, {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    }).then((res) => {
      if (res?.status === 200) {
        setDataDetail(res?.data?.response);
      }
    });
    return res;
  };

  useEffect(() => {
    if (params?.id) {
      setLoading(true);
      getDetailRule(params?.id).then((res) => setLoading(false));
    }
  }, [params]);

  const SendEmail = () => {
    const payload = {
      title: dataDetail.title,
      content: dataDetail.content,
    };
    Swal.fire({
      icon: "info",
      title: "This information will send to all employees!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await sendEmailToGroup(payload, {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        });
        if (res.data.meta.status === 200) {
          Swal.fire("Send email successfully!", "success");
        }
      }
    });
  };

  return (
    <Spin spinning={loading}>
      <Row align="space-around" wrap={true} gutter={[16, 16]}>
        <Col
          span={18}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
          className="container"
        >
          <div style={{ textAlign: "right" }}></div>
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: dataDetail?.content,
            }}
          ></div>
        </Col>

        <Col span={6}>
          <div style={{ position: "sticky", top: "100px" }}>
            <Information value={dataDetail} />

            <div wrap style={{display: "flex", alignItems:"center", justifyContent:"space-between", width:"100%"}}>
              
              <Button
                type="primary"
                className="site-layout__content__button-save"
                onClick={() => {
                  navigate(`/edit/${params?.id}`);
                }}
              >
                Edit
              </Button><Button type="primary" style={{background: "#fff", color: "#000", fontWeight: 600}} onClick={() => SendEmail()}>
                Send email
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

export default ViewPage;
