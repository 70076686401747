const BASE_API_URL_COMPANY_POLICY = process.env.REACT_APP_API_URL_TRAINING;
export const API_COMPANY_POLICY_INFO = {
  LIST_COMPANY_RULES: `${BASE_API_URL_COMPANY_POLICY}/api/rule-fines`,
  DETAIL_COMPANY_RULES: `${BASE_API_URL_COMPANY_POLICY}/api/rule-fines/detail`,
  UPLOAD_RULE_DOC: `${BASE_API_URL_COMPANY_POLICY}/api/rule-fines/upload-document`,
  UPLOAD_IMAGE_BASE64: `${BASE_API_URL_COMPANY_POLICY}/api/rule-fines/upload-image-base64`,
  CREATE_RULE: `${BASE_API_URL_COMPANY_POLICY}/api/rule-fines/store`,
  UPDATE_RULE: `${BASE_API_URL_COMPANY_POLICY}/api/rule-fines/update`,
  DELETE_RULE: `${BASE_API_URL_COMPANY_POLICY}/api/rule-fines/delete`,
  SEND_EMAIL: `${BASE_API_URL_COMPANY_POLICY}/api/rule-fines/send-mail`,
};
