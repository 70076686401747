import { buildQueryString } from "../../utils/functions";
import { ENDPOINT } from "../endpoint";
import { deleteData, getData, postData, postFile, putData } from "../request";

export const getListCompanyRules = async (header) => {
  return await getData({
    url: ENDPOINT.LIST_COMPANY_RULES,
    headers: header
  });
};
export const getDetailCompanyRules = async (id, header) => {
  return await getData({
    url: ENDPOINT.DETAIL_COMPANY_RULES + `/${id}`,
    headers: header
  });
};

export const uploadRuleDoc = async (data, header) => {
  const file = new FormData();
  file.append('document', data);
  return await postFile({url: ENDPOINT.UPLOAD_RULE_DOC, payload:file, headers: header });
};
export const uploadImageBase64 = async (data, header) => {
  const file = new FormData();
  file.append('image', data);
  return await postFile(
   { url: ENDPOINT.UPLOAD_IMAGE_BASE64,
    payload: file,headers: header}
  );
};

export const deleteRule = async (id, header) => {
  return await deleteData(
    {
      url: ENDPOINT.DELETE_RULE + `/${id}`,
      headers: header
    }
  );
};

export const createRule = async (payload ,header) => {
  return await postData(
    {
      url: ENDPOINT.CREATE_RULE,
      payload,
      headers: header
    }
  );
};
export const updateRule = async (id, payload, header) => {
  return await putData({
    url: ENDPOINT.UPDATE_RULE + `/${id}`,
    payload,
    headers: header
  });
};

export const sendEmailToGroup = async (payload, header) => {
  return await postData({
    url: ENDPOINT.SEND_EMAIL,
    payload,
    headers: header
  });
};