import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteRule,
  getDetailCompanyRules,
  getListCompanyRules,
  updateRule,
} from "../api/app-service/companyPolicyService";
import { Button, Col, Input, Row, Spin, TreeSelect, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { TextEditorFullTKG } from "../components/TextEditorFullTKG";
import Swal from "sweetalert2";
import Context from "../utils/context";
import { renderTreeSelect, transformToNested } from "../utils/functions";
import ContentComponent from "../components/ContentComponent";
const { Title, Paragraph } = Typography;

const EditPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [contentDetail, setContentDetail] = useState("");
  const [title, setTitle] = useState("");
  const [dataDetail, setDataDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [expandedKeys, setExpandedKeys] = useState();
  const context = useContext(Context);
  const onChange = (newValue) => {
    setSelectedID(newValue);
  };
  const getDetailRule = async (id) => {
    const res = await getDetailCompanyRules(id, {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}).then((res) => {
      if (res?.status === 200) {
        setDataDetail(res?.data?.response);
        setContentDetail(res?.data?.response?.content);
        setTitle(res?.data?.response?.title);
        setSelectedID(res?.data?.response?.parent_id)
      }
    });
    return res;
  };

  useEffect(() => {
    if (params?.id) {
      setLoading(true);
      getDetailRule(params?.id).then((res) => setLoading(false));
    }
  }, [params]);

  const handleEdit = async () => {
    if (title && contentDetail) {
      const payload = {
        ...dataDetail,
        title: title,
        content: contentDetail,
        documents: [],
        parent_id: selectedID || null,
      };
      setLoading(true);
      await updateRule(params?.id, payload, {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}).then(async (res) => {
        if (res?.status === 200) {
          await getListCompanyRules({Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}).then((res) => {
            if (res?.data?.meta?.status === 200) {
              const dataRes = res?.data?.response;
              const dataList = transformToNested(dataRes);
              context.setListMenu(dataList);
            } else {
              console.log("error fetch list");
            }
          });
          navigate(`/view/${params?.id}`);
        }
      });
      setLoading(false);
    } else {
      Swal.fire({
        text: title ? "Content cannot be null!" : "Title cannot be null!",
        icon: "warning",
        confirmButtonText: "Confirm",
      });
    }
  };

  const handleDelete = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        await deleteRule(params?.id, {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}).then(async (res) => {
          if (res?.status === 200) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            await getListCompanyRules({Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}).then((res) => {
              if (res?.data?.meta?.status === 200) {
                const dataRes = res?.data?.response;
                const dataList = transformToNested(dataRes);
                context.setListMenu(dataList);
              } else {
                console.log("error fetch list");
              }
            });
            navigate(`/`);
          }
        });
        setLoading(false);
      }
    });
  };
  return (
    <Spin spinning={loading}>
      <Row gutter={12}>
        <Col span={24}>
          <div className="site-layout__content__button-top">
            <Button
              type="primary"
              className="site-layout__content__button-delete"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </Col>
        <Col span={16}>
          <Paragraph style={{
          margin: "0 5px",
        }}>Title</Paragraph>
          <Input
            className="site-layout__content__input"
            placeholder="Title here"
            size="large"
            value={title}
            required
            onChange={(e) => setTitle(e?.target?.value)}
          />
        </Col>
        <Col span={8}>
          <Paragraph style={{
          margin: "0 5px",
        }}>Sub-Menu (Skip if root menu)</Paragraph>
          <TreeSelect
            showSearch
            size="large"
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            value={context.listMenu?.length > 0 ? selectedID : ""}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
            placeholder="Select Parent"
            allowClear
            // treeExpandedKeys={[expandedKeys]}
            // onTreeExpand={((key) => {console.log(key)} )}
            onChange={onChange}
            treeData={renderTreeSelect(context.listMenu || [])}
          />
        </Col>
      </Row>

      <TextEditorFullTKG data={contentDetail} setData={setContentDetail} />
      <div style={{ display: "flex" }}>
        <div className="site-layout__content__button-div">
          <Button
            // loading={deleting}
            type="primary"
            className="site-layout__content__button-back"
            onClick={() => navigate(`/view/${params?.id}`)}
            icon={<ArrowLeftOutlined />}
          >
            Back To View
          </Button>
        </div>
        <div style={{ flex: 1 }}></div>
        <div className="site-layout__content__button-div">
          <Button
            type="primary"
            className="site-layout__content__button-save"
            onClick={handleEdit}
          >
            Save
          </Button>
        </div>
      </div>
    </Spin>
  );
};

export default EditPage;
