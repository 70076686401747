import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import EditorToolbar, {
  formats,
  modules,
} from './ToolbarQuillEditor';
import { Button, Space } from 'antd';

const ReactQuillStyled = styled(ReactQuill)`
  .ql-container {
    min-height: 10rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    font-family: Nunito Sans, sans-serif;
  }

  .ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }

  .ql-toolbar.ql-snow {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-color: #e3e6ef;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: #e3e6ef;
    background-color: #fff;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  }
`;

export const TextEditorFullTKG = ({ data, setData }) => {
  const refEditor = useRef(null);
  const [content, setContent] = useState('');  
  function handleChangeInput(
    content,
    delta,
    source,
    editor,
  ) {
      setData(content);
  }

  useEffect(() => {
    if (data) {
      setContent(data);
    }
  }, [data]);


  return (
    <div style={{width: "100%"}}>
      <EditorToolbar />
      <ReactQuillStyled
        style={{ height: '600px', width: "100%", overflow: "hidden", paddingBottom: "50px" }}
        forwardedRef={refEditor}
        modules={modules}
        formats={formats}
        theme="snow"
        value={data || ''}
        onChange={handleChangeInput}
      />
    </div>
  );
};
