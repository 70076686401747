import React, { useReducer } from 'react';
import HomePage from './page/HomePage';
import * as ACTIONS from './store/actions/actions';
import * as MenuReducer from './store/reducers/menuReducer';
import Context from './utils/context';

function App() {
  const [stateMenuReducer, dispatchMenuReducer] =
    useReducer(
      MenuReducer.MenuReducer,
      MenuReducer.initialState,
    );

  const handleSetListMenu = (listMenu) => {
    dispatchMenuReducer(ACTIONS.setListMenu(listMenu));
  };


  return (
    <Context.Provider
      value={{
        listMenu: stateMenuReducer.listMenu,
        setListMenu: (listMenu) => handleSetListMenu(listMenu),
      }}
    >
      <HomePage />
    </Context.Provider>
  );
}

export default App;
