import React from "react";
import { Quill } from "react-quill";
import QuillImageDropAndPaste from "quill-image-drop-and-paste";
import {
  uploadImageBase64,
  uploadRuleDoc,
} from "../api/app-service/companyPolicyService";

Quill.register("modules/imageDropAndPaste", QuillImageDropAndPaste, true);

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

function insertToEditor(url, quillCustom) {
  // console.log(quillCustom.getSelection()?.index);
  quillCustom.insertEmbed(quillCustom.getSelection()?.index, "image", url);
  // quill.insertEmbed(null, 'image', url);
}
function saveToServer(fileImport, quillCustom) {
  // insertToEditor('nam');
  if (fileImport) {
    uploadRuleDoc(fileImport, {
      Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
    }).then((response) => {
      if (response?.data?.meta?.status === 200) {
        insertToEditor(response.data.response.preview, quillCustom);
      } else {
        console.log(response);
      }
    });
  }
}

function imageHandlerPaste(imageDataUrl, type, imageData) {
  imageData
    .minify({
      maxWidth: 320,
      maxHeight: 320,
      quality: 0.7,
    })
    .then((miniImageData) => {
      const blob = miniImageData.toBlob();
      const file = miniImageData.toFile();
      const formData = new FormData();

      // append blob data
      formData.append("file", blob);

      // or just append the file
      formData.append("file", file);

      // upload image to your server
      uploadImageBase64(imageDataUrl, {
        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
      }).then((response, error) => {
        console.log(response, "123");
        if (error) return;
        // success? you should return the uploaded image's url
        // then insert into the quill editor
        let index = (this.quill.getSelection() || {}).index;
        if (index === undefined || index < 0) index = this.quill.getLength();
        this.quill.insertEmbed(index, "image", response.data.response.preview);
      });
      // create a form data, and upload to the server...
    });
}

function imageHandler(a) {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();

  input.onchange = () => {
    const file = input.files[0];

    // file type is only image.
    if (/^image\//.test(file.type)) {
      saveToServer(file, this.quill);
    } else {
      console.log("You could only upload images.");
    }
  };
}
// Modules object for setting up the Quill editor
export const modules = {
  imageDropAndPaste: {
    // add an custom image handler
    handler: imageHandlerPaste,
  },
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      image: imageHandler,
    },
  },
  clipboard: {
    matchVisual: false,
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

// Quill Toolbar component
const QuillToolbar = () => (
  <div
    id="toolbar"
    style={{
      borderRadius: "10px 10px 0 0",
      border: "1px solid #e3e6ef",
    }}
  >
    <span className="ql-formats">
      <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      {/* <select className="ql-size" defaultValue="medium">
        <option value="extra-small">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium">Size 3</option>
        <option value="large">Size 4</option>
      </select> */}
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-align" value=""></button>
      <button className="ql-align" value="center"></button>
      <button className="ql-align" value="right"></button>
      <button className="ql-align" value="justify"></button>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
      <button className="ql-video" />
    </span>
    <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span>
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
  </div>
);

export default QuillToolbar;
