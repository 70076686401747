import axios from "axios";

const defaultHeader = {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_TRAINING,
  timeout: 5000000,
  headers: defaultHeader,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return error.response;
  }
);

export const getData = async ({ url, headers = defaultHeader }) => {
  const res = await instance.get(url, {
    headers,
  });
  return res;
};

export const postData = async ({ url, payload, headers = defaultHeader }) => {
  const res = await instance.post(url, payload, {
    headers,
  });
  return res;
};
export const postFile = async ({ url, payload, headers = defaultHeader }) => {
  const res = await instance.post(url, payload, {
    headers,
  });
  return res;
};

export const putData = async ({ url, payload, headers = defaultHeader }) => {
  const res = await instance.put(url, payload, {
    headers,
  });
  return res;
};
export const deleteData = async ({ url, headers = defaultHeader }) => {
  const res = await instance.delete(url, {
    headers,
  });
  return res;
};
