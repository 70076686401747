import moment from "moment";
import "moment-timezone";
// import { useNavigate } from "react-router-dom";

export function compareFnTimePopUp(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  // a must be equal to b
  return 0;
}

export const formatDateTimeByTimeZone = (date, format) => {
  return moment
    .utc(date)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format(format);
};

export const changeDateTimeZone = (date, timeZone) => {
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      })
    );
  }
  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  );
};


export function transformToNested(data) {
  let items = {};
  let roots = [];

  // Create items by id reference
  data.forEach((item) => {
    items[item.id] = item;
    items[item.id].children = [];
  });

  // Populate children and identify roots
  data.forEach((item) => {
    if (item.parent_id && items[item.parent_id]) {
      items[item.parent_id].children.push(item);
    } else {
      roots.push(item);
    }
  });

  return roots;
}

export const renderTreeSelect = (listMenu) => {
  const listTreeSelect = listMenu.map((item) => {
    // return {
    //       value: item.id,
    //       children: null,
    //       title: item.title,
    //     };
    if (item?.children?.length > 0) {
        return {
          value: item.id,
          children: renderTreeSelect(item?.children),
          title: item.title,
        };
    } else {
      return {
        value: item.id,
        children: item.children,
        title: item.title,
        disabled: item.parent_id ? true : false
      };
    }
  });
  return listTreeSelect;
};

export const renderTreeMenu = (listMenu) => {
  const listTreeSelect = listMenu.map((item) => {
    if (item?.children?.length > 0) {
      return {
        key: item.id,
        children: renderTreeMenu(item?.children),
        label: item.title,
      };
    } else {
      return {
        key: item.id,
        children: null,
        label: item.title,
      };
    }
  });
  
  return listTreeSelect;
};
