import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Quill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
const ReactQuillStyled = styled(Quill)`
  .ql-container {
    min-height: 10rem;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    font-family: Nunito Sans, sans-serif;
  }

  .ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
  }

  .ql-toolbar.ql-snow {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-color: #e3e6ef;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-color: #e3e6ef;
    background-color: #fff;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  }
`;
const ContentComponent = ({ data, setData }) => {

  const quillRef = useRef(null);

  const modules = {
    toolbar: [
      [
        { header: '1' },
        { header: '2' },
        'blockquote',
        'code-block',
      ],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      [{ font: [] }, { size: [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'super' }, { script: 'sub' }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],

      ['link', 'formula'],
      ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    'header',
    'align',
    'color',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
  ];

  const [content, setContent] = useState('');

  useEffect(() => {
    if (data) {
      setContent(data);
    }
  }, [data]);
  function handleChangeInput(
    content,
    delta,
    source,
    editor,
  ) {
    setData(content);
  }

  return (
    <div>
      <ReactQuillStyled
        style={{ height: '600px', width: "100%", overflow: "hidden", paddingBottom: "50px" }}
        forwardedRef={quillRef}
        modules={modules}
        formats={formats}
        value={data || ''}
        onChange={handleChangeInput}
        theme="snow"
      />
    </div>
  );
};

export default ContentComponent;
