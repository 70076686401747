import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  createRule,
  getDetailCompanyRules,
  getListCompanyRules,
} from "../api/app-service/companyPolicyService";
import ContentComponent from "../components/ContentComponent";
import { Button, Col, Form, Input, Row, Select, Spin, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { TextEditorFullTKG } from "../components/TextEditorFullTKG";
import Swal from "sweetalert2";
import { TreeSelect } from "antd";
import Context from "../utils/context";
import {renderTreeSelect, transformToNested } from "../utils/functions";
const { Title, Paragraph } = Typography;

const CreatePage = () => {
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();
  const [contentDetail, setContentDetail] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const context = useContext(Context);
  const onChange = (newValue) => {
    setSelectedID(newValue);
  };
  const handleCreate = async () => {
    if (title && contentDetail) {
      const payload = {
        title: title,
        content: contentDetail,
        documents: [],
        parent_id: selectedID || null,
      };
      setLoading(true);
      await createRule(payload, {Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}).then(async (res) => {
        if (res?.status === 200) {
          await getListCompanyRules({Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`}).then((res) => {
            if (res?.data?.meta?.status === 200) {
              const dataRes = res?.data?.response;
              const dataList = transformToNested(dataRes)
              context.setListMenu(dataList)
            } else {
              console.log('error fetch list');
            }
          })

          navigate(`/view/${res?.data?.response}`);
        }
      });
    } else {
      Swal.fire({
        text: title ? "Content cannot be null!" : "Title cannot be null!",
        icon: "warning",
        confirmButtonText: "Confirm",
      });
    }

    setLoading(false);
  };
  
  return (
    <Spin spinning={loading}>
      <Row gutter={12}>
        <Col span={16}>
        <Paragraph style={{
          margin: "0 5px",
        }}>Title</Paragraph>
          <Input
            className="site-layout__content__input"
            placeholder="Title here"
            size="large"
            value={title}
            required
            onChange={(e) => setTitle(e?.target?.value)}
          />
        </Col>
        <Col span={8}>
        <Paragraph style={{
          margin: "0 5px",
        }}>Sub-Menu (Skip if root menu)</Paragraph>
          <TreeSelect
            showSearch
            size="large"
            style={{
              width: "100%",
              marginBottom: "20px",
            }}
            value={selectedID}
            dropdownStyle={{
              maxHeight: 400,
              overflow: "auto",
            }}
            placeholder="Please select"
            allowClear
            treeDefaultExpandAll
            onChange={onChange}
            treeData={renderTreeSelect(context.listMenu || [])}
          />
        </Col>
      </Row>

      <TextEditorFullTKG data={contentDetail} setData={setContentDetail} />
      <div className="site-layout__content__button-div">
        <Button
          type="primary"
          className="site-layout__content__button-save"
          onClick={handleCreate}
        >
          Save
        </Button>
      </div>
    </Spin>
  );
};

export default CreatePage;
