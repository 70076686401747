import React, {
  useEffect,
  useState,
} from "react";
import "../index.scss";

import { Layout, Drawer, notification, Typography } from "antd";
import LayoutSider from "../components/LayoutSider";
import LayoutHeader from "../components/LayoutHeader";
import { Outlet } from "react-router-dom";
const { Sider } = Layout;
const { Title } = Typography;

function HomePage() {
  const [api, contextHolder] = notification.useNotification();
  const [visible, setVisible] = React.useState(false);
  const [dateState, setDateState] = useState(new Date());
  const [contentData, setContentData] = useState("");
  useEffect(() => {
    setInterval(() => {
      setDateState(new Date());
    }, 1000);
  }, []);

  useEffect(() => {
    const token = sessionStorage.getItem("accessToken");
    if (!token) {
      window.location.replace(process.env.REACT_APP_DOMAIN_SSO);
    }
  }, []);

  const openDrawer = () => setVisible(!visible);

  return (
    <>
      {contextHolder}
      <Layout>
        <LayoutHeader openDrawer={openDrawer} visible={visible} />

        <div
          style={{
            background: "#fff",
            boxShadow:
              "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
            padding: 10,
          }}
        >
          <Title
            level={1}
            style={{
              margin: 0,
              // opacity: 0.7,
              height: 40,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span
              style={{
                cursor: "pointer",
                margin: "auto 0",
                fontSize: "1.8rem",
              }}
            >
              {dateState.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </span>
          </Title>
        </div>
        <Layout className="site-layout">
          <Sider className="sider" width={330}>
            <LayoutSider setContentData={setContentData} />
          </Sider>
          <Drawer
            title={false}
            placement={"left"}
            closable={false}
            onClose={() => setVisible(false)}
            open={visible}
            key={"left"}
            width={270}
          >
            <Sider className="siderTablet" width={270}>
              <LayoutSider setContentData={setContentData} />
            </Sider>
          </Drawer>

          <div className="site-layout__content"  style={{width: "100%"}}>
            <Outlet />
          </div>
        </Layout>
      </Layout>
    </>
  );
}

export default HomePage;
