import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Spin, Typography } from 'antd';
import { verifyToken } from '../api/app-service/verifyService';
import "../index.scss"
const VerifyPage = () => {
  const navigate = useNavigate();
  const param = useParams();
  const token = param.token;
  const [loading, setLoading] = useState(true);
  const [failed, setFailed] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const verifyPage = async (token) => {
    const res = await verifyToken({Authorization: `Bearer ${token}`}).then(res => {
        if (res.data.meta.status === 200) {
            sessionStorage.setItem(
              'user',
              JSON.stringify(res?.data?.response),
            );
            navigate(`/view/${5}`);
          } else {
            setFailed(true);
          }
    })
    return res
  }
  useEffect(() => {
    if(token) {
      sessionStorage.setItem('accessToken', token);
      setLoading(true);
      verifyPage(token).then(res => setLoading(false))
    }

  }, [token]);

  return (
    <div className="verify-background">
      {loading && <Spin style={{ margin: 'auto' }} />}
      {failed && (
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography className="title-verify title-verify-failed">
            You have no permission!
          </Typography>
          <CloseCircleOutlined
            style={{
              fontSize: '120px',
              color: 'red',
              margin: 'auto',
            }}
          />
          <br />
        </div>
      )}
    </div>
  );
};

export default VerifyPage;
