import { API_COMPANY_POLICY_INFO } from "./api-url/company";
import { API_COMPANY } from "./api-url/verify";



export const ENDPOINT = {
  // Verify
  VERIFY: API_COMPANY.VERIFY,

  // Company
  LIST_COMPANY_RULES: API_COMPANY_POLICY_INFO.LIST_COMPANY_RULES,
  DETAIL_COMPANY_RULES: API_COMPANY_POLICY_INFO.DETAIL_COMPANY_RULES,
  UPLOAD_RULE_DOC: API_COMPANY_POLICY_INFO.UPLOAD_RULE_DOC,
  UPLOAD_IMAGE_BASE64: API_COMPANY_POLICY_INFO.UPLOAD_IMAGE_BASE64,
  CREATE_RULE: API_COMPANY_POLICY_INFO.CREATE_RULE,
  UPDATE_RULE: API_COMPANY_POLICY_INFO.UPDATE_RULE,
  DELETE_RULE: API_COMPANY_POLICY_INFO.DELETE_RULE,
  SEND_EMAIL: API_COMPANY_POLICY_INFO.SEND_EMAIL,
};
